<template>
  <transition :appear="true" name="slide">
    <div @touchmove.prevent class="container">
      <back back-url="/msg"></back>
      <scroll
        ref="scroll"
        class="scroll"
        :data="msgList"
      >
        <div>
          <empty description="暂无消息" v-show="showEmpty"/>
          <div class="msg-list">
            <div class="msg-item" v-for="(item,index) in msgList" :key="index">
              <div class="title">
                <div
                  class="user-read"
                  :class="{read: item.msg_user_read === 1,'no-read': item.msg_user_read === 0}"
                ></div>
                <div class="user-no-read"></div>
                系统通知
              </div>
              <div class="msg-type">【{{ item.msg_type }}】</div>
              <div class="msg-block" v-show="item.group.length===0">
                <div class="msg-desc"><span class="omit"> 问：{{ item.msg_desc }}</span></div>
              </div>
              <div
                class="msg-block"
                v-show="item.group.length!==0"
                v-for="(vo,k) in item.group"
                :key="k"
              >
                <div class="msg-desc" v-show="vo.create_person === userGuid">问：{{ vo.msg_desc }}</div>
                <div class="msg-desc answer" v-show="vo.create_person !== userGuid">答：{{ vo.msg_desc }}</div>
                <ul class="msg-media">
                  <li
                    class="pic-block"
                    v-for="(v,i) in vo.msg_media"
                    :key="i"
                    @click="previewImage(vo.msg_media,v.media_file)"
                  >
                    <img
                      class="pic"
                      :src="v.media_file"
                      alt=""/>
                  </li>
                </ul>
                <div class="time">{{vo.create_time}}</div>
              </div>
              <div class="btn" @click="groupMsg(index)">
                <span v-show="!item.unfold">展开</span>
                <span v-show="item.unfold">收起</span>
              </div>
            </div>
          </div>
        </div>
      </scroll>
    </div>
  </transition>
</template>

<script>
import Back from '@/components/back/Back'
import Scroll from '@/components/scroll/scroll'
import wx from 'weixin-js-sdk'
import { mapGetters } from 'vuex'
import { getMsg, getMsgGroup } from '@/api/Msg'
import { Toast, Empty } from 'vant'

const page = 1
const limit = 50
export default {
  name: 'Msg',
  components: {
    Back,
    Scroll,
    Empty
  },
  data () {
    return {
      showEmpty: false,
      msgList: [],
      unfold: false
    }
  },
  computed: {
    ...mapGetters([
      'userGuid',
      'agencyGuid'
    ])
  },
  mounted () {
    this._getMsg()
  },
  methods: {
    _getMsg () {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: '加载中...'
      })
      getMsg({
        page,
        limit,
        c_user_guid: this.userGuid
      }).then(res => {
        const { code, msg, data } = res
        if (code === 200) {
          this.msgList = this.msgList.concat(data.list)
          if (this.msgList.length === 0) {
            this.showEmpty = true
          }
          Toast.clear()
        } else {
          Toast.fail(msg)
        }
      })
    },
    groupMsg (index) {
      if (this.msgList[index].unfold) {
        this.msgList[index].unfold = !this.msgList[index].unfold
        this.msgList[index].group.splice(0, this.msgList[index].group.length)
        this.refresh()
      } else {
        Toast.loading({
          duration: 0,
          forbidClick: true,
          message: '加载中...'
        })
        getMsgGroup({
          msg_group: this.msgList[index].msg_group
        }).then(res => {
          const { code, msg, data } = res
          if (code === 200) {
            this.msgList[index].unfold = !this.msgList[index].unfold
            this.msgList[index].group = data
            this.refresh()
            Toast.clear()
          } else {
            Toast.fail(msg)
          }
        })
      }
    },
    previewImage (medias, current) {
      let urls = []
      medias.forEach((item) => {
        urls.push(item.media_file)
      })
      wx.previewImage({
        current: current,
        urls: urls
      })
    },
    refresh () {
      setTimeout(() => {
        this.$refs.scroll.refresh()
      }, 100)
    }
  }
}
</script>

<style scoped lang="stylus">
@import "~@/assets/stylus/mixin"
.slide-enter-active, .slide-leave-active
  transition: all 0.3s

.slide-enter, .slide-leave-to
  transform: translate3d(100%, 0, 0)

.container
  position absolute
  z-index 10
  top 0
  left 0
  right 0
  bottom 0
  background rgba(245, 245, 244, 1)

  .scroll
    position absolute
    top 80px
    left 0
    right 0
    bottom 0
    overflow hidden

.msg-list
  display flex
  justify-content center
  flex-wrap wrap
  width 100%
  padding-top 20px

  .msg-item
    width 690px
    padding 30px
    margin 10px 0
    box-sizing border-box
    border-radius 10px
    background rgba(255, 255, 255, 1)

    .title
      display flex
      align-items center
      width 100%
      font-size 30px
      color rgba(153, 153, 153, 1)
      font-weight bold

      .user-read
        width 26px
        height 26px
        margin-right 10px
        background-size 100% 100%
        background-repeat no-repeat

        &.no-read
          bg-image('~@/assets/img/msg/no-read')

        &.read
          bg-image('~@/assets/img/msg/read')

    .msg-type
      width 100%
      margin-top 20px
      font-size 26px
      color rgba(51, 51, 51, 1)

    .msg-block
      width 100%

      .msg-desc
        display flex
        align-items center
        width 100%
        margin-top 40px
        font-size 26px
        color rgba(153, 153, 153, 1)
        white-space pre-wrap

        &.answer
          color rgba(51, 51, 51, 1)

        .omit
          width 100%
          line-height normal
          no-wrap()

      .msg-media
        display flex
        flex-wrap wrap

        .pic-block
          width 80px
          height 80px
          margin 10px 10px 0 0

          .pic
            width 100%
            height 100%
      .time
        width 100%
        text-align right
        margin-top 20px
        font-size 24px
        color rgba(153, 153, 153, 1)

    .btn
      display flex
      align-items center
      line-height normal
      width 100%
      padding-top 20px
      margin-top 30px
      font-size 26px
      color rgba(31, 120, 229, 1)
      border-top 2px solid rgba(215, 213, 213, 1)
</style>
